.animate__animated {
    animation-duration: 3s;
    animation-fill-mode: both;
}

.animate__fadeInUp {
    animation-name: fadeInUp;
}

.animate__fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 50%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}