@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Playfair Display;
    src: url(../public/fonts/PlayfairDisplay-VariableFont_wght.ttf);
}

.playfair {
    font-family: Playfair Display;
}

@font-face {
    font-family: Inter;
    src: url(../public/fonts/Inter-VariableFont_slnt\,wght.ttf);
}

.inter {
    font-family: Inter;
}

html {
    scroll-behavior: smooth;
}

.parallax {
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
